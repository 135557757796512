<script>
import SleekButton from '@/components/ui/modern/SleekButton.vue'

export default {
  name: 'CompanyCamUserMapping',
  components: { SleekButton },
  props: {
    enabled: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      isLoading: true,
      isSaving: false,
      userMappings: [],
      updatedMappings: [],
      ccamUsers: []
    }
  },
  methods: {
    async fetchUsersWithMapping() {
      const { payload } = await this.$store.dispatch('ajax', {
        path: 'CompanyCam/fetchUsers',
        data: {
          // We can use the scope for the moment, but we may need this later
          company_id: this.$store.state.session.company.company_id
        }
      })

      this.userMappings = payload
    },
    async fetchCompanyCamUsers() {
      const { payload } = await this.$store.dispatch('ajax', {
        path: 'CompanyCam/fetchCompanyCamUsers',
        data: {
          // We can use the scope for the moment, but we may need this later
          company_id: this.$store.state.session.company.company_id
        }
      })

      this.ccamUsers = JSON.parse(payload.message)
    },
    changeMapping(e, id) {
      // Remove any change we may have had before
      this.updatedMappings = this.updatedMappings.filter((mapping) => mapping.internal !== id)
      this.updatedMappings.push({
        internal: id,
        external: e
      })
    },
    async saveUsers() {
      this.isSaving = true

      await this.$store.dispatch('ajax', {
        path: 'CompanyCam/updateUserMappings',
        data: {
          // We can use the scope for the moment, but we may need this later
          company_id: this.$store.state.session.company.company_id,
          mappings: this.updatedMappings
        }
      })

      this.updatedMappings = []

      this.isSaving = false
    }
  },
  computed: {
    wrangledCcamUsers() {
      const users = []

      this.ccamUsers.forEach((v) => {
        users.push({
          text: `${v.first_name} ${v.last_name}`,
          value: v.id
        })
      })

      return users
    },
    buttonText() {
      return this.isSaving ? 'Saving ...' : 'Save Users'
    }
  },
  watch: {
    async enabled() {
      if (this.enabled) {
        await this.fetchUsersWithMapping()
        await this.fetchCompanyCamUsers()
      }
    }
  },
  async mounted() {
    this.isLoading = true
    if (this.enabled) {
      await this.fetchUsersWithMapping()
      await this.fetchCompanyCamUsers()
    }
    this.isLoading = false
  }
}
</script>

<template>
  <SpinContainer :loading="isLoading">
    <CardSection>
      <CardList>
        <CardListField v-for="userMapping in userMappings" :key="userMapping.user_id">
          <div>{{ userMapping.user_fname }} {{ userMapping.user_lname }}</div>
          <Choose
            placeholder="Select User..."
            :static-set="wrangledCcamUsers"
            :value="userMapping.external_id"
            @input="changeMapping($event, userMapping.user_id)"
          ></Choose>
        </CardListField>
      </CardList>
    </CardSection>
    <SleekButton @click.native="saveUsers">{{ buttonText }}</SleekButton>
  </SpinContainer>
</template>

<style scoped lang="scss"></style>
