<script>
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import SleekButton from '@/components/ui/modern/SleekButton.vue'
import FieldSection from '@/components/ui/fields/FieldSection.vue'
import _ from 'lodash'

InputSwitch.compatConfig = { MODE: 3 }
InputText.compatConfig = { MODE: 3 }

export default {
  name: 'CompanyCamSettings',
  components: {
    FieldSection,
    SleekButton,
    InputSwitch,
    InputText
  },
  data() {
    return {
      isLoading: true,
      isSaving: false,
      enabled: false,
      settings: {
        exportQuotes: false,
        exportUsers: false,
        importProjects: false
      },
      initialSettings: {
        exportQuotes: false,
        exportUsers: false,
        importProjects: false
      },
      apiToken: null,
      initialApiToken: null
    }
  },
  methods: {
    async checkEnabled() {
      const { payload } = await this.$store.dispatch('ajax', {
        path: 'CompanyCam/getActivationStatus',
        data: {
          // We can use the scope for the moment, but we may need this later
          company_id: this.$store.state.session.company.company_id
        }
      })

      this.enabled = payload.activated
    },
    async fetchSettings() {
      const { payload } = await this.$store.dispatch('ajax', {
        path: 'CompanyCam/getCompanySettings',
        data: {
          // We can use the scope for the moment, but we may need this later
          company_id: this.$store.state.session.company.company_id
        }
      })

      this.initialApiToken = payload.apiToken
      this.apiToken = this.initialApiToken

      if (payload.settings !== null) {
        const payloadSettings = JSON.parse(payload.settings)
        this.initialSettings.exportQuotes = payloadSettings.exportQuotes
        this.initialSettings.importProjects = payloadSettings.importProjects
        this.initialSettings.exportUsers = payloadSettings.exportUsers

        this.settings.exportQuotes = payloadSettings.exportQuotes
        this.settings.importProjects = payloadSettings.importProjects
        this.settings.exportUsers = payloadSettings.exportUsers
      }
    },
    async saveSettings() {
      this.isSaving = true
      let saved = false

      // If any of our settings have changed, we actually need to do something
      if (this.apiToken !== this.initialApiToken) {
        // Update API token
        try {
          const { payload } = await this.$store.dispatch('ajax', {
            path: 'CompanyCam/saveApiToken',
            data: {
              // We can use the scope for the moment, but we may need this later
              company_id: this.$store.state.session.company.company_id,
              token: this.apiToken
            }
          })

          if (payload.success) {
            // Update our initial API token
            this.initialApiToken = this.apiToken
            // And make sure we are enabled
            this.enabled = payload.enabled
            saved = true
          }
        } catch (e) {
          this.$store.dispatch('alert', {
            error: true,
            message: e.userMessage,
            text: 'Unable to save API token. Please verify it before trying again.'
          })
        }
      }

      if (!_.isEqual(this.initialSettings, this.settings)) {
        try {
          const { payload } = await this.$store.dispatch('ajax', {
            path: 'CompanyCam/saveCompanySettings',
            data: {
              // We can use the scope for the moment, but we may need this later
              company_id: this.$store.state.session.company.company_id,
              settings: this.settings
            }
          })

          if (payload.success) {
            this.initialSettings.exportQuotes = this.settings.exportQuotes
            this.initialSettings.importProjects = this.settings.importProjects
            this.initialSettings.exportUsers = this.settings.exportUsers
            saved = true
          }
        } catch (e) {
          this.$store.dispatch('alert', {
            error: true,
            message: e.userMessage,
            text: 'Unable to save settings. Please verify before trying again.'
          })
        }
      }

      if (saved) {
        this.$store.dispatch('alert', {
          text: 'Settings Saved Successfully'
        })
      }

      this.isSaving = false
    }
  },
  computed: {
    buttonText() {
      return this.isSaving ? 'Saving ...' : 'Save Settings'
    }
  },
  watch: {
    enabled() {
      this.$emit('update:enabled', this.enabled)
    }
  },
  async mounted() {
    this.isLoading = true
    await this.checkEnabled()
    if (this.enabled) {
      await this.fetchSettings()
    }
    this.isLoading = false
  }
}
</script>

<template>
  <SpinContainer :loading="isLoading">
    <div class="flex flex-col gap-2 my-8">
      <label for="apitoken">API token</label>
      <InputText id="apitoken" v-model="apiToken" aria-describedby="api-token-help" />
      <small id="api-token-help"
        >You can obtain an API Token from CompanyCam by navigating to Your Company > Access Tokens >
        New Token and generating a new token.</small
      >
    </div>
    <div v-if="enabled" class="flex flex-col gap-2 mb-8">
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col gap-1 pr-4">
          <span>Synchronise Bolster quotes to CompanyCam</span>
          <small class="text-muted"
            >When enabled, quotes created in Bolster will also be set up as linked Projects within
            your CompanyCam account.</small
          >
        </div>
        <InputSwitch v-model="settings.exportQuotes" />
      </div>
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col gap-1 pr-4">
          <span>Synchronise CompanyCam projects to Bolster</span>
          <small class="text-muted"
            >When enabled, Projects that are created in CompanyCam will also be set up as linked
            Quotes within Bolster.</small
          >
        </div>
        <InputSwitch v-model="settings.importProjects" />
      </div>
      <div class="flex flex-row justify-between items-center">
        <div class="flex flex-col gap-1 pr-4">
          <span>Synchronise Bolster users to CompanyCam</span>
          <small class="text-muted"
            >When enabled, users created in Bolster will be matched to existing users in CompanyCam,
            where possible, or created as new users.</small
          >
        </div>
        <InputSwitch v-model="settings.exportUsers" />
      </div>
    </div>
    <SleekButton @click.native="saveSettings">{{ buttonText }}</SleekButton>
  </SpinContainer>
</template>

<style scoped lang="scss"></style>
