<script>
import CompanyCamSettings from '@/components/companycam/Settings.vue'
import SleekThinHeader from '@/components/ui/modern/SleekThinHeader.vue'
import CompanyCamProjectMapping from '@/components/companycam/ProjectMapping.vue'
import CompanyCamUserMapping from '@/components/companycam/UserMapping.vue'

export default {
  components: {
    CompanyCamUserMapping,
    CompanyCamProjectMapping,
    SleekThinHeader,
    CompanyCamSettings
  },
  data() {
    return {
      enabled: false
    }
  },
  methods: {
    updateEnabled(val) {
      this.enabled = val
    }
  }
}
</script>

<template>
  <div>
    <SleekThinHeader>
      <template #title>CompanyCam</template>
      <template #subtitle>Integrate Bolster with CompanyCam in just a few easy steps</template>
    </SleekThinHeader>
    <div class="px-safe-4 md:px-safe-8 pb-safe-2">
      <CompanyCamSettings @update:enabled="updateEnabled" />
      <CompanyCamProjectMapping v-if="enabled" :enabled="enabled" />
      <CompanyCamUserMapping v-if="enabled" :enabled="enabled" />
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
