<script>
import SleekButton from '@/components/ui/modern/SleekButton.vue'

export default {
  name: 'CompanyCamProjectMapping',
  components: { SleekButton },
  props: {
    enabled: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      isLoading: true,
      isSaving: false,
      quoteMappings: [],
      updatedMappings: [],
      ccamProjects: []
    }
  },
  methods: {
    async fetchProjectsWithMapping() {
      const { payload } = await this.$store.dispatch('ajax', {
        path: 'CompanyCam/fetchProjects',
        data: {
          // We can use the scope for the moment, but we may need this later
          company_id: this.$store.state.session.company.company_id
        }
      })

      this.quoteMappings = payload
    },
    async fetchCompanyCamProjects() {
      const { payload } = await this.$store.dispatch('ajax', {
        path: 'CompanyCam/fetchCompanyCamProjects',
        data: {
          // We can use the scope for the moment, but we may need this later
          company_id: this.$store.state.session.company.company_id
        }
      })

      this.ccamProjects = JSON.parse(payload.message)
    },
    changeMapping(e, id) {
      // Remove any change we may have had before
      this.updatedMappings = this.updatedMappings.filter((mapping) => mapping.internal !== id)
      this.updatedMappings.push({
        internal: id,
        external: e
      })
    },
    async saveProjects() {
      this.isSaving = true

      await this.$store.dispatch('ajax', {
        path: 'CompanyCam/updateProjectMappings',
        data: {
          // We can use the scope for the moment, but we may need this later
          company_id: this.$store.state.session.company.company_id,
          mappings: this.updatedMappings
        }
      })

      this.updatedMappings = []

      this.isSaving = false
    }
  },
  computed: {
    wrangledCcamProjects() {
      const projects = []

      this.ccamProjects.forEach((v) => {
        projects.push({
          text: v.name || v.address.street_address_1,
          value: v.id
        })
      })

      return projects
    },
    buttonText() {
      return this.isSaving ? 'Saving ...' : 'Save Projects'
    }
  },
  watch: {
    async enabled() {
      if (this.enabled) {
        await this.fetchProjectsWithMapping()
        await this.fetchCompanyCamProjects()
      }
    }
  },
  async mounted() {
    this.isLoading = true
    if (this.enabled) {
      await this.fetchProjectsWithMapping()
      await this.fetchCompanyCamProjects()
    }
    this.isLoading = false
  }
}
</script>

<template>
  <SpinContainer :loading="isLoading" v-if="quoteMappings.length">
    <CardSection>
      <CardList>
        <CardListField v-for="quoteMapping in quoteMappings" :key="quoteMapping.quote_id">
          <div>{{ quoteMapping.quote_name }}</div>
          <Choose
            placeholder="Select Project..."
            :static-set="wrangledCcamProjects"
            :value="quoteMapping.external_id"
            @input="changeMapping($event, quoteMapping.quote_id)"
          ></Choose>
        </CardListField>
      </CardList>
    </CardSection>
    <SleekButton @click.native="saveProjects">{{ buttonText }}</SleekButton>
  </SpinContainer>
</template>

<style scoped lang="scss"></style>
