<script>
export default {
  name: 'SleekThinHeader'
}
</script>

<template>
  <div id="sth-header-container">
    <container
      class="center"
      style="height: 100%; position: relative; flex-direction: row; flex-wrap: auto"
    >
      <div class="sth-header-text" style="flex: 1">
        <div class="sth-header-main-text">
          <p class="sth-new-feature-highlight"><slot name="featureHighlight"></slot></p>
          <p class="sth-header-title"><slot name="title"></slot></p>
          <p class="sth-header-subtitle"><slot name="subtitle"></slot></p>
        </div>
      </div>
    </container>
  </div>
</template>

<style scoped lang="scss">
#sth-header-container {
  width: 100%;
  height: 100vh;
  background-color: $level-yellow;
  background-repeat: no-repeat;
  color: $pitch-black;

  @media (min-width: 768px) {
    height: 33vh;
    padding: 0 100px;
    background-position: right 100px center;
    background-size: auto;
  }

  @media (max-width: 480px) {
    background-image: none !important;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    background-position: right 100px bottom 32px;
    background-size: 40%;
  }
}

.sth-header-text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}

.sth-new-feature-highlight {
  color: $pitch-black;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 26.4px */
  text-transform: uppercase;
}

.sth-header-title {
  color: $pitch-black;
  font-size: 58px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 70.4px */
  margin: 24px 0;
}

.sth-header-subtitle {
  color: $pitch-black;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
}
</style>
